import React, { useEffect, useState } from 'react'
import Logo from '../Logo/Logo'
import { Avatar, Box, Button, Image, Menu, MenuButton, MenuDivider, VStack, MenuItem, MenuList, useToast, useDisclosure, Switch, HStack, Text, Divider } from '@chakra-ui/react'
import "./styles.scss";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slices/userSlice';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase.config';
import { HiOutlineCog8Tooth } from "react-icons/hi2";
import { getTextColor } from '../../helper/random.helper';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toastDefaultConfig } from '../../utils/constants';
import { toggleManageSection } from '../../redux/slices/sectionEditSlice';
import { HexColorPicker, HexColorInput } from "react-colorful";
import EditModal from "../../atoms/EditModal";
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { login } from '../../redux/slices/userSlice';
import { AiOutlineLayout } from 'react-icons/ai';
import TemplateDrawer from '../TemplateDrawer/TemplateDrawer';
import { toggleLogin } from '../../redux/slices/StatusSlice';
import { TiEdit } from 'react-icons/ti';
import { LuLayoutPanelLeft } from 'react-icons/lu';
import { PiPencilRuler } from 'react-icons/pi';

const Header = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [URLPath, setURLPath] = useState();
  const [color, setColor] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isOpen: isTemplateDrawerOpen, onOpen: onTemplateDrawerOpen, onClose: onTemplateDrawerClose } = useDisclosure();
  const { username } = useParams();

  const [UserOwnProfile, setUserOwnProfile] = useState(false);
  console.log(!!username, (user.username !== username));

  useEffect(() => {
    if (!!username && (user.username !== username)) {
      setUserOwnProfile(false);
    } else {
      setUserOwnProfile(true);
    }
  }, [user.username, username])


  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, { theme: [user?.theme?.[0], color, !!user?.theme?.[2]] });
      dispatch(login({ ...user, theme: [user?.theme?.[0], color, !!user?.theme?.[2]] }));
      onClose();
    } catch (error) {
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    setColor(user?.theme?.[1])
  }, [user.theme])


  useEffect(() => {
    setURLPath(window?.location?.pathname);
  }, [window?.location?.pathname])

  const toast = useToast();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      dispatch(logout());
      dispatch(toggleLogin(false));
      navigate('/');
    }).catch((error) => {
      toast({
        title: 'Error',
        description: `Something went wrong! Please try again.`,
        status: 'error',
        ...toastDefaultConfig
      })
    });
  }

  const toggleEmailVisibility = async () => {
    try {
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, {
        private_email: !user?.private_email
      });
      dispatch(login({
        ...user,
        private_email: !user?.private_email
      }))
    } catch (error) {
      toast({
        title: 'Error',
        description: `Something went wrong! Please try again.`,
        status: 'error',
        ...toastDefaultConfig
      })
    }
  }

  return (
    <Box className="wrap_all" minWidth={"350px"} zIndex={999}>
      <Box shadow={type === 'private' ? "md" : "none"} border={type === 'private' ? "1px solid #E8E8E8" : "none"}
        background={type === 'private' ? "rgb(255, 255, 255)" : "rgba(246, 248, 237, 0.5)"}
        className="header_wrapper">
        <Box className="header_content">
          {type === 'private' ?
            <>
              <Box cursor={"pointer"} onClick={() => navigate('/')} display={{ base: "none", sm: "block" }}>
                <Logo height={"35"} fontSize="22" />
              </Box>
              <Box cursor={"pointer"} onClick={() => navigate('/')} display={{ base: "block", sm: "none" }}>
                <Logo height={"35"} fontSize="22" hideText={true} />
              </Box>
            </> :
            <Box cursor={"pointer"} onClick={() => navigate('/')} >
              <Logo height={"35"} fontSize="22" />
            </Box>}


          {
            type === "private" ?
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap="10px">
                {/* <Link to="/work">
              <Menu colorScheme='brand'>
                <MenuButton title="Work">
                  {URLPath === '/work' ? <HiBriefcase size={26} /> : <HiOutlineBriefcase size={26} />}
                </MenuButton>
              </Menu>
            </Link> */}
                {user?.onBoardingCompleted &&
                  UserOwnProfile && <>
                    <Menu colorScheme='brand' >
                      <Box onClick={() => dispatch(toggleManageSection())} cursor={"pointer"}>
                        <PiPencilRuler size={24} />
                      </Box>
                    </Menu>
                    <Menu colorScheme='brand'>
                      <Box onClick={onTemplateDrawerOpen} cursor={"pointer"}>
                        <LuLayoutPanelLeft size={24} />
                        <TemplateDrawer isOpen={isTemplateDrawerOpen} onOpen={onTemplateDrawerOpen} onClose={onTemplateDrawerClose} />
                      </Box>
                    </Menu>
                  </>
                }
                {user?.onBoardingCompleted &&
                  UserOwnProfile &&
                  <Menu colorScheme='brand' position="relative">
                    <Box onClick={onOpen} rounded="full" height={"22px"} width={"22px"} border={"2px solid"} borderColor="#000" background={user?.theme?.[1]} cursor={"pointer"}></Box>
                    <EditModal
                      isOpen={isOpen}
                      onOpen={onOpen}
                      onClose={onClose}
                      headerText={'Theme Picker'}
                      saveFn={onSubmit}
                      IsSubmitting={isSubmitting}>
                      <VStack m={4}>
                        <HexColorPicker color={color} onChange={setColor} />
                        <HexColorInput color={color} onChange={setColor} />
                      </VStack>
                    </EditModal>
                  </Menu>
                }
                <Menu border={"none"} shadow={'md'}>
                  <MenuButton title="Settings" marginTop="-2px">
                    <HiOutlineCog8Tooth size={26} />
                  </MenuButton>
                  <MenuList backgroundColor={"#fff"} border={"2px solid"} borderColor={'#E8E8E8'} shadow={'xl'}>
                    {user?.onBoardingCompleted &&
                      UserOwnProfile &&
                      <>
                        <MenuItem
                          _hover={{
                            backgroundColor: "transparent",
                          }}
                          closeOnSelect={false}
                          backgroundColor={"#fff"} fontWeight={'500'}>
                          <HStack w="full" justifyContent={"space-between"} alignItems={"center"}>
                            <Text>Private Email</Text>
                            <Switch
                              isChecked={user?.private_email}
                              onChange={toggleEmailVisibility} />
                          </HStack>
                        </MenuItem>
                      </>
                    }
                    <Divider />
                    <MenuItem
                      _hover={{ backgroundColor: user?.theme?.[1], color: getTextColor(user?.theme?.[1]) ? 'brand.white' : 'brand.black' }}
                      backgroundColor={"#fff"} onClick={handleSignOut} fontWeight={'500'}>
                      Log out @{user?.username}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box> :
              <>
                <div className="other_menu">
                  {/* <p className='hidable' onClick={() => scrollToSection('about')}>About</p> */}
                  {/* <p className='hidable' onClick={() => scrollToSection('objectives')}>Objectives</p> */}
                  <p onClick={() => navigate("/get-started")}>Login</p>
                  <Button onClick={() => navigate("/get-started?sign_up=true")} colorScheme='brand' size='md'>
                    Sign up
                  </Button>
                  {/* <Button colorScheme='brand' size='md' onClick={() => openLink("https://x.com/RehumanWork")}>
              Join the Converstion
            </Button> */}
                </div>
              </>
          }
        </Box>
      </Box >
    </Box>
  )
}

export default Header