import React from 'react';
import './styles.scss';
import { Link } from '@chakra-ui/react';
import Logo from '../Logo/Logo';
import moment from 'moment';

const Footer = ({ color }) => {
  return (
    <div className="footer_container">
      {/* <div className='row'>
        <Link>Terms of use</Link>
        <Link>Policy Policy</Link>
      </div> */}
      <div className='site'>
        {/* <p>&copy;&nbsp;{moment().format('YYYY')}</p> */}
        <Link _hover={{ textDecoration: "none" }} href="/">
          <Logo height={"30"} fontSize={"16"} color={color} />
        </Link>
      </div>
    </div>
  )
}

export default Footer